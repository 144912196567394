<template>
    <v-container>
        <v-row>
            <v-col class="col-12">
                <img src="@/assets/logo/logo.svg" />
                <h1 class="text-center">
                    Όροι χρήσης
                </h1>
            </v-col>
            <v-col class="col-12">
                <p>
                    <span class="font-weight-bold d-block">
                        Γενικά
                    </span>
                    Το παρόν έγγραφο («Έγγραφο») αναφέρεται στους Όρους Χρήσης
                    που διέπουν τη χρήση της εφαρμογής #LittleGreenFarmers,
                    στους διακομιστές που χρησιμοποιεί η εφαρμογή και σε όλες
                    τις υπηρεσίες #LittleGreenFarmers που παρέχει η ιδιοκτήτρια
                    εταιρεία ΑΓΡΟΤΙΚΕΣ ΕΦΑΡΜΟΓΕΣ ΙΚΕ («Εταιρεία»). Οι παρόντες
                    Όροι Χρήσης αποτελούν κατά συνέπεια τη συμφωνία σας με την
                    «Εταιρεία».
                </p>

                <p>
                    <span class="font-weight-bold d-block">
                        Αποδοχή Συμφωνίας
                    </span>
                    Όταν χρησιμοποιείτε την εφαρμογή #LittleGreenFarmers, είτε
                    έχοντας δημιουργήσει λογαριασμό είτε όχι, αποδέχεστε τους
                    Όρους Χρήσης που αναλύονται στο «Έγγραφο». Καλείστε να τους
                    διαβάσετε προσεκτικά και σε περίπτωση που δε συμφωνείτε μαζί
                    τους, σας ζητείται να μη χρησιμοποιήσετε την εφαρμογή. Κατά
                    καιρούς η «Εταιρεία» ενδέχεται να τροποποιεί τους Όρους
                    Χρήσης προκειμένου να αντανακλούν με ακρίβεια τις πολιτικές
                    της. Επιπλέον, ενδέχεται να τροποποιηθεί και η ίδια η
                    εφαρμογή για τη βελτίωση των παρεχόμενων υπηρεσιών.
                    Ενδέχεται να σας ζητηθεί η αποδοχή συμπληρωματικών όρων που
                    σχετίζονται με συγκεκριμένες υπηρεσίες, οι οποίες θα
                    ενσωματώνονται στην εφαρμογή ανά διαστήματα. Εάν επιθυμείτε
                    να συνεχίσετε να χρησιμοποιείτε την εφαρμογή, θα δεσμεύεστε
                    από τους τροποποιημένους Όρους Χρήσης, ενώ έχετε το δικαίωμα
                    να διαγράψετε το λογαριασμό σας αν δεν θέλετε να αποδεχτείτε
                    αυτούς ή οποιουσδήποτε τροποποιημένους Όρους Χρήσης.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Περιορισμένη άδεια χρήσης
                    </span>
                    Η άδεια που σας παρέχεται για την εφαρμογή
                    #LittleGreenFarmers είναι αποκλειστικά για προσωπική σας
                    χρήση. Σε καμία περίπτωση, ακόμα και αν η «Εταιρεία»
                    προσφέρει δωρεάν τις υπηρεσίες και την εφαρμογή
                    #LittleGreenFarmers, δεν μπορείτε να πουλήσετε και να
                    αντιγράψετε τις υπηρεσίες και την εφαρμογή οπουδήποτε αλλού,
                    να μοιραστείτε την άδεια χρήσης με οποιονδήποτε άλλο ή να
                    επιχειρήσετε να κερδίσετε χρήματα από αυτήν χωρίς την
                    έγγραφη ρητή άδειά της «Εταιρείας». Όταν κατεβάζετε την
                    εφαρμογή σας δίνεται μια άδεια χρήσης για να εγκαταστήσετε
                    και να χρησιμοποιήσετε την εφαρμογή #LittleGreenFarmers
                    εντός των ορίων αυτής της συμφωνίας, αλλά δεν αποκτάτε
                    δικαιώματα ιδιοκτησίας αυτής. Εάν επιθυμείτε για κάποιο λόγο
                    να χρησιμοποιήσετε το λογισμικό, τον τίτλο, το εμπορικό
                    σήμα, το σήμα της υπηρεσίας, το λογότυπο, το όνομα τομέα
                    (domain name) ή/και οτιδήποτε άλλο ανήκει στην «Εταιρεία»,
                    πρέπει να λάβετε προηγούμενη γραπτή άδεια. Για να λάβετε
                    αιτήματα άδειας μπορείτε να απευθυνθείτε στο
                    info@agroapps.gr.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Χρήση και τερματισμός χρήσης υπηρεσιών και εφαρμογής
                    </span>
                    Κατά τη χρήση της εφαρμογής #LittleGreenFarmers,
                    αναλαμβάνετε την πλήρη ευθύνη και συμφωνείτε να μην την
                    χρησιμοποιήσετε με τρόπους για τους οποίους δεν έχετε
                    εξουσιοδοτηθεί ρητά. Είστε υπεύθυνοι για οποιαδήποτε χρήση
                    γίνεται μέσω του λογαριασμού σας. Η εφαρμογή
                    #LittleGreenFarmers είναι σχεδιασμένη για παιδιά, αλλά για
                    την είσοδο και τη χρήση της είναι απαραίτητη η επίβλεψη
                    ενήλικα. Επιπλέον, στην εφαρμογή λογαριασμό μπορούν να
                    δημιουργήσουν μόνο ενήλικες. Η «Εταιρεία» έχει το δικαίωμα
                    να αναστείλει ή να τερματίσει την άδεια χρήσης ή την
                    πρόσβασή σας στην εφαρμογή οποιαδήποτε στιγμή, για
                    οποιονδήποτε λόγο (π.χ. παραβίαση τυχόν ουσιωδών όρων του
                    παρόντος «Εγγράφου» ή δημιουργία βλάβης, κινδύνου ή πιθανών
                    νομικών συνεπειών για εμάς ή άλλους χρήστες).
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Περιεχόμενο
                    </span>
                    Έχοντας δημιουργήσει λογαριασμό στην εφαρμογή, μπορείτε να
                    μεταφορτώσετε ή να διαθέσετε δικό σας περιεχόμενο (πχ.
                    δημιουργία DIY έργου, περιγραφή-τοποθεσία, μεταφόρτωση
                    φωτογραφιών, υπερσυνδέσμου με βίντεο στη σελίδα YouTube),
                    για το οποίο είστε αποκλειστικά υπεύθυνοι. Το περιεχόμενο
                    απευθύνεται σε παιδιά, και δεν δημιουργείται ούτε παρέχεται
                    από την «Εταιρεία» ή οποιοδήποτε τρίτο μέρος.
                    <br />
                    Σε κάθε περίπτωση οφείλετε να συμμορφώνεστε με τους κανόνες
                    και τις διατάξεις του Ελληνικού, Ευρωπαϊκού και Διεθνούς
                    Δικαίου και τη σχετική νομοθεσία που διέπει τις
                    τηλεπικοινωνίες και να απέχετε από κάθε παράνομη και
                    καταχρηστική συμπεριφορά κατά την επίσκεψη/χρήση αυτής και
                    σε σχέση με αυτήν. Η επίσκεψη/χρήση της εφαρμογής πρέπει να
                    γίνεται αποκλειστικά για νόμιμους σκοπούς και σύμφωνα με τα
                    χρηστά ήθη και τους παρόντες όρους.
                    <br />
                    Ως εκ τούτου, οι επισκέπτες ενδεικτικά και όχι περιοριστικά
                    συμφωνούν ότι δεν θα κάνουν χρήση της εφαρμογής για:
                </p>

                <ol type="a">
                    <li>Να προξενήσουν βλάβη σε ανήλικο.</li>
                    <li>
                        Να μεταδώσουν ή να αποκτήσουν πρόσβαση σε περιεχόμενο
                        που:
                        <ol type="i">
                            <li>
                                παραβιάζει οποιαδήποτε δικαιώματα τρίτων (πχ
                                πνευματικής και βιομηχανικής ιδιοκτησίας),
                            </li>
                            <li>
                                προσβάλει την προσωπικότητα τρίτων (π.χ.
                                συκοφαντικό, ρατσιστικό περιεχόμενο),
                            </li>
                            <li>
                                έρχεται σε αντίθεση με το νόμο και τα χρηστά και
                                συναλλακτικά ήθη,
                            </li>
                            <li>
                                παρενοχλεί με οποιονδήποτε τρόπο την ιδιωτική
                                ζωή και τα ατομικά και κοινωνικά δικαιώματα
                                τρίτων.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Να παραπλανήσουν οποιονδήποτε ως προς την προέλευση του
                        περιεχομένου που αναρτούν, να ζημιώσουν, με οποιονδήποτε
                        τρόπο, τη φήμη των ιδιοκτητών ή τρίτων, να θέσουν σε
                        κίνδυνο την ασφάλεια του δικτύου τους, να εμποδίσουν
                        οποιονδήποτε επισκέπτη να έχει πρόσβαση στην εφαρμογή ή
                        οποιοδήποτε υπηρεσία στεγάζεται στο δίκτυο της εταιρίας
                        ή να παρακάμπτουν τον έλεγχο της ταυτότητάς τους από
                        αυτήν.
                    </li>
                    <li>
                        Να εγκαταστήσουν και προωθήσουν, με οποιοδήποτε τρόπο,
                        οποιουδήποτε είδους αυτόκλητη ή μη εξουσιοδοτημένη
                        διαφήμιση ή μη ζητηθέντα από το λήπτη ηλεκτρονικά
                        μηνύματα (spam) και οποιαδήποτε άλλη μορφή ανεπιθύμητης
                        προώθησης περιεχομένου, καθώς και να εγκαταστήσουν και
                        προωθήσουν διαφημίσεις χωρίς την έγγραφη συναίνεση της
                        εταιρίας.
                    </li>
                    <li>
                        Να εγκαταστήσουν, προωθήσουν ή/και διαθέσουν περιεχόμενο
                        που περιέχει ιούς λογισμικού ή οποιοδήποτε άλλο
                        ηλεκτρονικό κώδικα, αρχεία ή προγράμματα σχεδιασμένα να
                        παρεμβληθούν, καταστρέψουν ή περιορίσουν τη λειτουργία
                        οποιουδήποτε λογισμικού ή τηλεπικοινωνιακού εξοπλισμού ή
                        να εμποδίσουν άλλους επισκέπτες να χρησιμοποιούν την
                        εφαρμογή.
                    </li>
                </ol>
                <p>
                    Ο επισκέπτης/χρήστης ευθύνεται και αναλαμβάνει να
                    αποκαταστήσει οποιαδήποτε ζημία προκληθεί στην εταιρία ή/και
                    την εφαρμογή από παράνομη ή αθέμιτη ή αντίθετη εν γένει στα
                    χρηστά ήθη και τους παρόντες όρους χρήση της εφαρμογής και
                    των υπηρεσιών που προσφέρονται μέσω αυτής.
                    <br />
                    Για την πρόσβαση σε ορισμένες υπηρεσίες ή για τη σύνδεση με
                    την εφαρμογή, ενδέχεται να ζητηθούν τα στοιχεία των
                    επισκεπτών με σκοπό την απονομή σχετικού κωδικού (ή κωδικών)
                    πρόσβασης. Τα στοιχεία αυτά δεν γνωστοποιούνται σε τρίτους
                    υπό οποιεσδήποτε συνθήκες. Τα ανωτέρω στοιχεία, πρέπει να
                    είναι πάντοτε αληθή, ακριβή, έγκυρα και πλήρη. Οι επισκέπτες
                    είναι υπεύθυνοι για όλες τις πράξεις που διενεργούνται με
                    τον κωδικό πρόσβασης και υποχρεούνται να ειδοποιούν άμεσα
                    την ιδιοκτήτρια εταιρεία για οποιαδήποτε μη εξουσιοδοτημένη
                    χρήση αυτού και για οποιαδήποτε (ακόμα και για ενδεχόμενη)
                    παραβίαση ασφαλείας. Η εταιρία δεν ευθύνεται για οποιαδήποτε
                    βλάβη ή ζημία τυχόν προκύψει από την αυθαίρετη ή παράνομη
                    χρήση του κωδικού πρόσβασης από τρίτους, λόγω διαρροής του ή
                    για οποιονδήποτε άλλο λόγο και διατηρεί το δικαίωμα να
                    ζητήσει την καταβολή αποζημίωσης από τον επισκέπτη, σε
                    περίπτωση που υποστεί οποιουδήποτε είδους ζημία από την
                    αυθαίρετη ή παράνομη χρήση του κωδικού πρόσβασης. Η εταιρία
                    δικαιούται οποτεδήποτε να αρνηθεί την απονομή κωδικού
                    πρόσβασης ή να ακυρώσει απονεμηθέντα κωδικό ή να τερματίσει
                    την παροχή των υπηρεσιών αυτών στον επισκέπτη και να αρνηθεί
                    οποιαδήποτε τρέχουσα ή μέλλουσα χρήση του παρόντος δικτυακού
                    τόπου σε περίπτωση παραβίασης των Όρων Χρήσης.
                </p>

                <p>
                    <span class="font-weight-bold d-block">
                        Επεξεργασία ή διαγραφή λογαριασμού
                    </span>
                    Έχετε το δικαίωμα οποιαδήποτε στιγμή να επεξεργαστείτε τα
                    στοιχεία του λογαριασμού σας, καθώς επίσης σε περίπτωση που
                    δεν επιθυμείτε να χρησιμοποιήσετε ξανά την εφαρμογή
                    #LittleGreenFarmers, έχετε το δικαίωμα να διαγράψετε τον
                    λογαριασμό σας από την εφαρμογή. Η διαγραφή του λογαριασμού
                    σας πραγματοποιείται άμεσα εφόσον αιτηθείτε αυτής μέσω της
                    εφαρμογής #LittleGreenFarmers.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Υπηρεσίες τρίτων
                    </span>
                    Περιστασιακά, η «Εταιρεία» μπορεί να συμπεριλάβει ή να
                    προσφέρει προϊόντα ή υπηρεσίες τρίτων μερών («Υπηρεσίες
                    τρίτων») στην εφαρμογή (πχ. υλικά για αισθητήρες από
                    «Υπηρεσίες τρίτων»), οι πάροχοι των οποίων έχουν και την
                    πλήρη (αστική και ποινική) ευθύνη για την ασφάλεια, το
                    σύννομο και την εγκυρότητα του περιεχομένου των ιστοσελίδων
                    και υπηρεσιών τους, αποκλειομένης κάθε ευθύνης της
                    εταιρείας, όπως ενδεικτικά ευθύνης περί δικαιωμάτων
                    πνευματικής και βιομηχανικής ιδιοκτησίας ή οποιουδήποτε
                    δικαιώματος τρίτου. Ως εκ τούτου οι επισκέπτες υποχρεούνται
                    να συμμορφώνονται με τους όρους χρήσης των παρόχων των
                    Υπηρεσιών Τρίτων Μερών ή/και των ιστοσελίδων αυτών και να
                    απευθύνονται απευθείας σε αυτούς για οτιδήποτε προκύψει από
                    την επίσκεψη ή/και χρήση αυτών. Παρόλα αυτά, η ακεραιότητα
                    της εφαρμογής #LittleGreenFarmers απασχολεί ιδιαίτερα την
                    «Εταιρεία» και για το λόγο αυτό οποιαδήποτε σχόλια σχετικά
                    με αυτούς τους ιστοτόπους είναι ευπρόσδεκτα.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Επιβολή δικαιωμάτων
                    </span>
                    Η «Εταιρεία» διατηρεί το δικαίωμα να παρακολουθεί την
                    πρόσβαση ή χρήση της εφαρμογής, για να διασφαλίσει τη
                    συμμόρφωση σας με την παρούσα συμφωνία και την ισχύουσα
                    νομοθεσία και σε περίπτωση παραβίασης τους, διατηρεί το
                    δικαίωμα να αποκαλύψει παράνομες συμπεριφορές στις αρχές και
                    να συνεργαστεί μαζί τους για τη δίωξη των χρηστών που
                    παραβιάζουν τη νομοθεσία.
                    <br />
                    Η εταιρία καταβάλλει κάθε δυνατή προσπάθεια και λαμβάνει
                    πρόσφορα οργανωτικά μέτρα και μέτρα ασφαλείας στα πλαίσια
                    του τεχνολογικού ελέγχου, προκειμένου οι υπηρεσίες και τα
                    περιεχόμενα της εφαρμογής να παρέχονται απρόσκοπτα και χωρίς
                    διακοπή. Δεν ευθύνεται όμως στην περίπτωση που για
                    οποιονδήποτε λόγο, συμπεριλαμβανομένης και της περίπτωσης
                    αμέλειας, διακοπεί η λειτουργία της εφαρμογής ή καθίσταται
                    δυσχερής ή/και αδύνατη η πρόσβαση σε αυτή, ή αν παρά τα
                    τηρούμενα μέτρα ασφαλείας, εντοπιστούν «ιοί» ή άλλο
                    επιβλαβές λογισμικό και μεταδοθούν στα τερματικά των
                    επισκεπτών/ χρηστών, ή αν τρίτα μη εξουσιοδοτημένα πρόσωπα
                    (“hackers”) παρέμβουν στο περιεχόμενο και στην λειτουργία
                    της εφαρμογής καθιστώντας δυσχερή την χρήση της ή
                    προκαλώντας προβλήματα στην ορθή λειτουργία της. Επίσης, η
                    εταιρία δεν φέρει ευθύνη για την προσωρινή μη διαθεσιμότητα
                    της εφαρμογής, για την διακοπή μεμονωμένων ή όλων των
                    λειτουργιών της ή για οποιαδήποτε δυσλειτουργία ή τεχνικά
                    θέματα που τυχόν εμφανισθούν.
                    <br />
                    Η ετιαρία, υπό οποιεσδήποτε συνθήκες, συμπεριλαμβανομένης
                    και της περίπτωσης αμέλειας, δεν ευθύνεται έναντι του χρήστη
                    ή οποιουδήποτε τρίτου για οποιασδήποτε μορφής ζημία, άμεση ή
                    έμμεση που σχετίζεται με οποιοδήποτε τρόπο με τη χρήση της
                    εφαρμογής, την πλοήγηση σε αυτή και τη χρήση των υπηρεσιών ή
                    των πληροφοριών που περιέχονται σε αυτή. Κάθε
                    επισκέπτης/χρήστης οφείλει να λαμβάνει όλα τα κατάλληλα
                    μέτρα ασφάλειας (π.χ. προγράμματα προστασίας από ιούς) πριν
                    από οποιαδήποτε επίσκεψη στην εφαρμογή. Το κόστος των
                    ενδεχόμενων διορθώσεων ή επισκευών, το αναλαμβάνει ο
                    επισκέπτης/ χρήστης και σε καμία περίπτωση η εταιρία.
                    <br />
                    Η εταιρία δεν φέρει, έναντι των χρηστών, ευθύνη για τυχόν
                    διαρροή των προσωπικών τους δεδομένων λόγω χρήσης εκ μέρους
                    τους, μέσα από την εφαρμογή, προϊόντων και υπηρεσιών τρίτων.
                </p>
                <p>
                    <span class="font-weight-bold d-block">
                        Επικοινωνία
                    </span>
                    Για οποιεσδήποτε παρατηρήσεις ή ερωτήσεις σχετικά με
                    οποιοδήποτε μέρος της εφαρμογής #LittleGreenFarmers ή
                    οποιοδήποτε μέρος αυτού του «Εγγράφου», αλλά και για την
                    αναφορά παραβίασης δικαιωμάτων πνευματικής ιδιοκτησίας,
                    εμπορικού σήματος ή άλλης πνευματικής ιδιοκτησίας, μπορείτε
                    να απευθυνθείτε στο info@agroapps.gr.
                </p>
                Έκδοση 2022.6
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "TermsOfUse",
};
</script>
